module.exports = {
  actions: {
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    edit_options: "Edit Visibility Options",
    share: "Share",
    update: "Update",
    upload: "Upload",
    yes_remove: "Yes, Remove",
    yes: "Yes",
    no: "No",
    not: "Not",
    ok: "OK",
    add: "Add",
    create: "Create",
    transfer: "Transfer",
    close: "Close",
    send: "Send",
    open_in_recordings: "View in recordings",
    filter_by: "Filter by",
    filters: "Filters",
    play: "Play",
    pause: "Pause",
    resume: "Resume",
    download: "Download",
    fullscreen: "Fullscreen",
    undo: "Undo",
    redo: "Redo",
    blur: "Blur",
    crop: "Crop",
    previous: "Previous",
    next: "Next",
    back: "Back",
    copy_to_clipboard: "Copy to Clipboard",
    resend_invite: "Resend invite",
    delete_invite: "Delete invite",
    apply_filters: "Apply filters",
    collapse: "Collapse",
    go_to_media_hub: "Go to media hub",
    signin_with_google: "Sign in with Google",
    signup_with_google: "Sign up with Google",
    signin_with_microsoft: "Sign in with Microsoft",
    signup_with_microsoft: "Sign up with Microsoft",
    signin_with_keycloak: "Sign in with Company SSO",
    signup_with_keycloak: "Sign up with Company SSO",
    skip: "Skip",
    export_as: "Export as",
    configure: "Configure",
    compare: "Compare",
    compare_disabled: "Compare disabled when the player is active",
    apply: "Apply",
  },
  title: {
    long_press: "Please, long press the image to save.",
    long_press_to_open: "long press to open",
    long_press_ios_mp4_title: "iOS doesn't allow download MP4 file",
    long_press_ios_external_title: "Please long press this link to open it",
    long_press_mp4_text1:
      "To download this file we need to open this link on external browser.",
    long_press_mp4_text2:
      "Please long press this link to open the clip on external browser and try download clip again.",
    first_snapshot: "First",
    last_snapshot: "Last",
    required_fields_description: "indicates required fields",
    no_images_for_period: "There are no images for this time period",
    seconds: "Seconds",
    snapshots: "Snapshots",
    duration: "Duration",
    clip_type: "Clip Type",
    fps: "FPS",
    share_media: "Share Media",
    delete_media: "Delete Media",
    delete_sharer: "Delete Share",
    delete_preset: "Delete Preset",
    delete_account: "Delete Your Account",
    delete_comment: "Delete Comment",
    archive_comment: "Archive Comment",
    unarchive_comment: "Unarchive Comment",
    recording_schedule: "Recording Schedule",
    create_comment: "Add Comment",
    create_clip: "Create Clip",
    media_url: "Media URL",
    upload_file: "Upload File",
    save_to_gallery: "Save To Gallery",
    visibility_options: "Visibility Options",
    sharing_camera: "Sharing Camera",
    export: "Export",
    export_as: "Export As...",
    create_timelapse: "Create Time-Lapse",
    transfer_camera_ownership: "Transfer Camera Ownership",
    presets: "Presets",
    create_a_preset: "Add preset",
    send_to: "Send To",
    active_projects: "Active projects",
    completed_projects: "Completed projects",
    export_xray: "Export Xray",
  },
  sections: {
    projects: "Projects",
    cameras: "Cameras",
    BIMView: "BIM",
    droneView: "Drone",
    _360View: "360°",
    site_planner: "Site Planner",
    automations: "Automations",
    media_hub: "Media Hub",
    map_view: "Map View",
    video_wall: "Video Wall",
    live_chat: "Live Chat",
    tutorials: "Tutorials",
    settings: "Settings",
    sign_in: "Sign in",
    sign_out: "Sign out",
    sign_up: "Create new account",
    about: "About Evercam",
    blog: "Blog",
    gate_report: "Gate Report",
    company: "Company",
    feedback: "Leave feedback",
    user_manual: "User manual",
    updates: "Updates",
    timeline: "Timeline",
    copilot: "Copilot",
    copilotVideos: "Copilot Videos",
  },
  pages: {
    timeline: "Timeline",
    copilot: "Copilot",
    live_view: "Live View",
    recordings: "Recordings",
    edge_video: "Edge Video",
    sharing: "Sharing",
    media_hub: "Media Hub",
    compare: "Compare",
    gate_report: "Gate Report",
    bim_compare: "BIM Compare",
    site_planner: "Site Planner",
    account: "Account",
    activities: "Activities",
    live_view_widget: "Live View Widget",
    recordings_widget: "Recordings Widget",
    bim_compare__widget: "BIM Compare Widget",
    connectors: "Connectors",
    tutorials: "Tutorials",
    timelapse: "Time-Lapse",
    sign_up: "Sign up",
    progress_photos: "Progress Photos",
  },
  forms: {
    name: "Name",
    author: "Created By",
    camera: "Camera",
    title: "Title",
    sort_by: "Sort by",
    filters: "Filters",
    from_date: "From date",
    to_date: "To date",
    email: "Email",
    first_name: "First Name",
    last_name: "Last Name",
    language: "Language",
    unit_system: {
      label: "Unit System",
      items: {
        metric: "Metric",
        imperial: "Imperial",
      },
      meters: "Meters",
      feet_and_inches: "Feet and inches",
    },
    phone_international: "Phone Number",
    dial_code: "Phone Prefix",
    country: "Country",
    password: "Password",
    persona: {
      label: "How do you plan to use Evercam?",
      items: {
        hq: "Project Overview (e.g. Investor / Head Office)",
        pm: "Project Management (On Site)",
        qs: "Dispute Avoidance & Contract Administration (e.g. QS)",
        marketing: "Marketing",
        hs: "Incident Review & Continuous Improvement (e.g. Health & Safety)",
        di: "Digital / Innovation",
        other: "Other",
      },
    },
    optional: "optional",
  },
  content: {
    update_failed: "Failed to update {resource}",
    update_success: "{resource} updated successfully",
    company: {
      fetch_cameras_failed: "Failed to fetch company cameras!",
      fetch_projects_failed: "Failed to fetch company projects!",
      fetch_company_details_failed: "Failed to fetch company details!",
    },
    etimeline: {
      labels: {
        anpr: "ANPR ",
        bim: "BIM Compare ",
        exNvrRecordings: "ExNvr Video",
        safetyReports: "Safety Reports",
        constructionReports: "Construction Reports",
        siteActivity_workers: "Workers Activity",
        siteActivity_equipment: "Equipment Activity",
        siteActivity_vehicles: "Vehicles Activity",
        comments: "Comments",
      },
      snapshot_not_found: "Snapshot not found in the selected timestamp",
    },
    player_mode: {
      video: "Video",
      jpeg: "JPEGs",
    },
    snapshot_load_failed: "Failed to load snapshot!",
    beta_feature_text:
      "This feature is in beta. We'd love to receive feedback to",
    embed_code: "Embed this code on your webpage",
    public_camera: "public camera",
    custom_refresh_rate_text:
      "Number of seconds to wait before the next image refresh",
    live: "LIVE",
    pending: "...pending",
    you: "you",
    email_verified: "Email verified successfully.",
    show_thumbnails: "Thumbnails",
    copy_shareable_link: "Copy shareable link",
    copy_link: "Copy link",
    copy_success: "Copied to Clipboard",
    copy_error: "Copy to Clipboard failed",
    cloud_recording: "Cloud Recording",
    cloud_recording_tooltip_text:
      "A cloud recording clip takes images (1 jpeg/ 10 seconds) from the cloud and compresses them into a video. (e.g.: a 1-hour video will have 360 images compressed into about 15 minutes)",
    local_recording_tooltip_text:
      "A local recording clip takes the video footage (8 FPS) from the device (e.g.: a 1-hour video will be 1-hour in duration)",
    page_not_found_error:
      "The page you are looking for either no longer exists or you don't have access to it.",
    requested_by: "Requested By",
    share_request_revoke: "Share request reminder revoked",
    share_request_revoke_text:
      "Share request reminder has been revoked. You will not receive Evercam reminder emails on this share request.",
    good_bye_quote:
      "And fear not lest Existence closing your Account, and mine, should know the like no more; The Eternal Saki from that Bowl has pour’d Millions of Bubbles like us, and will pour.",
    calendar: "Calendar",
    pre_authenticate: "Pre-Authenticate",
    delay_recordings: "Restrict recent images",
    delay_recordings_by: "Delay by",
    timeline: "Timeline",
    dark_mode: "Dark mode",
    camera_status: {
      offline:
        "This camera is under maintenance. We're aware of the issue and are working on it.",
      under_maintenance:
        "This camera is under maintenance. We're aware of the issue and are working on it.",
      waiting_for_site_visit:
        "This camera is under maintenance. We're aware of the issue and are working on it.",
      waiting: "Waiting for Activation",
      decommissioned: "Decommissioned",
      on_hold:
        "Live View & Cloud Recordings are paused for this camera. If you have any questions, please contact your account manager.",
    },
    camera_statuses: {
      online: "Online",
      offline: "Offline",
      offline_scheduled: "Ofline Scheduled",
      under_maintenance: "Under Maintenance",
      waiting: "Waiting For Activation",
      decommissioned: "Decommissioned",
      waiting_for_site_visit: "Waiting For Site Visit",
      on_hold: "On Hold",
    },
    team_members: {
      title: "Team Members",
      add_member: "Add Member",
    },
    project_members: {
      title: "Members",
    },
    camera_discoverability: {
      is_public_short: "Public",
      is_discoverable_short: "Discoverable",
      is_private_short: "Private",
    },
    recordings: {
      events: "Events",
      playback_speed: "Playback speed",
      xray: "Xray",
      brain: "Brain",
      xray_disabled: "X-ray disabled when the player is active",
      brain_tool_disabled_on_live:
        "BrainTool disabled on live stream and autoplay",
      brain_tool_disabled_no_snapshot:
        "BrainTool disabled, No available snapshot",
      brain_tool_processing:
        "Processing the snapshot... Please wait, this may take up to 20 seconds.",
      brain_tool_error:
        "Processing failed. Please try again later or contact support if the problem persist.",
      brain_tool_no_results: "No results found for the selected snapshot",
      calendar: "Calendar",
      clarity_text: `
        <p class="caption mb-2"><strong>Evercam Recordings</strong></p>
          <p class="caption mb-1">
            This tool is intended for review of site conditions in 1 hour
            increments at 6 frames per second.
          </p>
          <p class="caption mb-1">
            Most Evercam customers use this tool for incident and safety
            reviews.
          </p>
          <p class="caption mb-0">
            You can contact
            <a target="self" href="mailto:support@evercam.io"
              >support@evercam.io</a
            >
            for full fidelity video for your project.
          </p>
      `,
      comments: "Comments",
    },
    activities: {
      logs_load_error: "Activity logs load failed. due to error",
    },
    sharing: {
      read_only_message: `
        <p class="caption">
          You have <strong>Read Only</strong> access to this
          <strong>private camera</strong>.
        </p>
        <p class="caption">
          In order to share this camera with other people, it must either be
          a <strong>public camera</strong> or you must have Full Rights to
          share.
        </p>
        <p class="caption">
          If you would like to share this camera, please request
          <strong>Read + Share rights</strong> access from the owner,
          <strong>Construction Evercam</strong> on construction@evercam.io
          or contact Evercam Live Support.
        </p>
        <p class="caption">
          With Evercam, it is possible to share your camera with as many
          people as you wish.
        </p>
        <p class="caption">Go ahead, share the love!</p>
      `,
    },
    progress_photo: {
      all: "All",
      new: "New Progress Photo",
      edit: "Edit Progress Photo",
      create: "Create a progress photo",
      delete: "Delete Progress Photo",
      create_success: "Progress Photo created successfully",
      create_failed: "Failed to create progress photo",
      delete_success: "Progress Photo deleted successfully",
      delete_failed: "Failed to delete progress photo",
      update_success: "Progress Photo updated successfully",
      update_failed: "Failed to update progress photo",
      resume_success: "Progress Photo resumed successfully",
      pause_success: "Progress Photo paused successfully",
      update_status_failed: "Failed to update pause status",
      delete_confirmation:
        "Are you sure you want to delete this progress photo ?",
      no_item: {
        heading_text:
          "Progress Photo is a scheduled email that contains a snapshot from your camera. Include as many emails and cameras as you like.",
        subheading_text1:
          "This clever feature sends you up-to-date, high-resolution images directly to your inbox.",
        subheading_text2:
          "Send images the day before a site meeting or send all project stakeholders a daily/weekly update.",
        subheading_text3:
          "Now everyone is updated, and you haven’t left your desk.",
      },
    },
    or_use: "or use",
    back_to_projects: "Back to projects",
    no_search_result: "No projects match your search.",
    no_search_result_for: "No result for query",
    no_project_sidebar: "No projects.",
    no_user_search_result: "No user found.",
    no_responding_server: "The server is not responding. We're working on it.",
    server_reconnecting_message:
      "This page will automatically check the server status and try reconnecting in",
    something_wrong_text:
      "Something went wrong. Please try again and, if the problem persists, contact support.",
    invalid_snapshot_timestamp_text:
      "Invalid snapshot timestamp, please try again.",
    further_assistance: "For further assistance please contact support on",
    no_project_text: "You have no projects in your account.",
    no_site_plans_text: "There are no 'site plans' to display.",
    no_camera_text:
      "The camera you are looking for either no longer exists or you don't have access to it.",
    no_project_exist_text:
      "The Project you are looking for either no longer exists or you don't have access to it.",
    no_connection_text:
      "It looks like you're not connected to the Internet. Please check your Internet connection.",
    start_time: "Start Time",
    start_date: "Start Date",
    select_timezone: "Select Timezone",
    email_addresses: "Email Addresses",
    gate_report: {
      camera: "Camera",
      project_has_no_gate_report: "Project doesn't have gate report",
      arrived_at: "In",
      left_at: "Out",
      unknown: "Unknown",
      vehicle_type: "Vehicle type",
      plate_number: "Plate number",
      plate_preview: "Plate preview",
      unprocessed: "Not processed",
      gates: "Gates",
      count_in: "in",
      count_out: "out",
      thumbnail: "Thumbnail",
      thumbnail_context: "Context",
      event_time: "Event Time",
      anpr_data: "ANPR Data",
      event_type: "Direction",
      export: {
        download: "Download",
        download_failed: "Failed to Download",
      },
      count_unknown: "n/a",
      filters: {
        all_cameras: "All Cameras",
        all_vehicles: "All Vehicle types",
        all_events: "All Events",
        last_week: "Last week",
        last_month: "Last month",
        overall: "Overall",
      },
      charts: {
        deliveries_by_truck_type: "Deliveries over time by vehicle type",
        total_deliveries_by_truck_type: "Total deliveries by vehicle",
      },
      failed_update_truck_type: "Failed to update truck type!",
      success_update_truck_type: "Truck type updated successfully!",
      failed_update_event_type: "Failed to update event type!",
      success_update_event_type: "Event type updated successfully!",
    },
    anpr: {
      directions: {
        all: "All",
        forward: "In",
        reverse: "Out",
        unknown: "n/a",
      },
    },
    generic_error_message:
      "An error occurred. Please try again and, if the problem persists, contact support.",
    lazy_load_error_message:
      "Loading failed, Please refresh the page, if the problem persists, contact support.",
    loading: "Loading",
    thumbnail: "Thumbnail",
    events: { in: "in", out: "out" },
    line: "Line",
    brush: "Brush",
    rectangle: "Rectangle",
    ellipse: "Ellipse",
    arrow: "Arrow",
    text: "Text",
    filters: "Filters",
    more: "More",
    less: "Less",
    status: "Status",
    since: "Since",
    timelapse: {
      no_snapshot_found: "No snapshots found in this period",
      period: "Period",
      schedule: "Schedule",
      "24_hours": "Last 24 Hours",
      "7_days": "Last 7 Days",
      "30_days": "Last 30 Days",
      whole_project: "Whole Project",
      continuous: "Continuous",
      working_hours: "Working Hours",
      timelapse_inprogress: "Time-Lapse request in progress.",
      timelapse_wait_time_text:
        "This may take about 10 minutes. Please check back later.",
      working_hours_description: "Mon to Fri, 8am to 6pm",
      custom: "Custom",
      note: `<p class="caption mb-0">
      This video is produced by Evercam’s Time-Lapse Creator. For professional videos, please contact the Video Team at <a href="mailto:video@evercam.io">video@evercam.io</a> or fill out this <a href="https://forms.zohopublic.com/Evercam/form/TimelapseFormV2/formperma/1Z5PiEzmFCLDG9K79mI0mAXxyaX8app_0QxuQer0MU4" target="_blank">form</a>
    </p>`,
      clarity_text: `
        <p class="caption mb-2"><strong>Evercam Time-Lapse Creator</strong></p>
          <p class="caption mb-0">
            This video is produced by Evercam’s Time-Lapse Creator. For professional videos, please contact the Video Team at <a href="mailto:video@evercam.io">video@evercam.io</a> or fill out this <a href="https://forms.zohopublic.com/Evercam/form/TimelapseFormV2/formperma/1Z5PiEzmFCLDG9K79mI0mAXxyaX8app_0QxuQer0MU4?referrername={project}" target="_blank">form</a>
          </p>
          <p class="mb-1"><strong>__________</strong></p>
          <p class="caption">
            This tool is intended for review of site conditions over longer durations.
          </p>
          <p class="caption mb-1">
            Most Evercam customers use this tool to communicate site progress and milestones.
          </p>
          <p class="caption">
            You can contact
            <a target="self" href="mailto:support@evercam.io"
              >support@evercam.io</a
            >
            for full fidelity video for your project.
          </p>
      `,
      custom_period: "Custom",
      "30_seconds": "30 Seconds",
      "60_seconds": "60 Seconds",
      "90_seconds": "90 Seconds",
      from: "From:",
      to: "To:",
      remove_timestamp: "Remove Timestamp",
      smooth_transition: "Smooth Frame Transition",
      logo: "Logo",
      success_message:
        "Your Time-Lapse is being generated and will be available in the Media Hub folder within 10 minutes.",
      creation_success: "Your Time-Lapse created successfully",
      help_title: "Time-Lapse",
      help_description_1:
        "Share what’s happening with your construction site instantly, develop your own Time-Lapse video in just a few clicks",
      help_description_2:
        "Select the period you want to focus on from any point throughout your project.",
      help_description_3:
        "We provide you with multiple export options so that your video is ready for social media, internal communications and progress updates.",
      help_description_4: "Want to create a custom video?",
      help_description_5:
        "No problem – our in-house video editors are still on hand to help bring your vision to life.",
      help_description_6: "Contact me today:",
      help_email: "erika@evercam.io",
      format_step: "Format",
      effects_step: "Effects",
      confirm_step: "Confirm",
      error_step: "Error",
      finish_step: "Finished",
      desc_duration: "Select a duration for exported your Time-Lapse video",
      desc_remove_timestamp:
        "This option removes the date and time displayed on the top left of your video",
      desc_smooth_transition:
        "When enabled, this will make the transition between the video frames smoother and each image fade into the other",
      desc_confirm:
        "Finally, choose a name for your Time-Lapse, then click save, and it will be available soon in your Media Hub section",
    },
    invalid_field: "Invalid field value: {target}",
    fetch_resource_failed: "Failed to fetch {resource}",
    logo: {
      add_success: "Logo added successfully",
      add_error: "Failed to upload the image, due to error",
    },
    media_hub: {
      share_disable_tooltip: "Media is not public",
      upload_file: {
        drag_file: "Drag your file here<br /> or click to browse",
        drag_again: "Failed!, click here or drag your file<br /> to try again",
        selected_file: "Selected File",
      },
      link_field_labels: {
        public_image: "Public Image",
        clip_video: "Clip Video",
        gif_file: "GIF File",
        mp4_file: "MP4 File",
        timelapse_video: "Time-Lapse Video",
      },
      timelapse_video: "Time-Lapse video",
      save_to: "Save to Media hub",
      save_success: "Xray saved to media hub successfully !",
      save_failed: "Failed save Xray to media hub !",
      save_image_success: "Your image has been saved to Media Hub",
      not_found: "Media not found or deleted",
      clip_not_found: "Media not found or deleted",
      create_failed: "Failed to create clip",
      delete_media: "Are you sure you want to delete this media ?",
      make_public_error: "Failed to make clip public.",
      make_public_success: "Media is now public",
      make_private_error: "Failed to make clip private.",
      make_private_success: "Media is now private",
      title_edit_success: "Title changed successfully",
      title_edit_error: "We can not change the title",
      edit_success: "Media modified successfully",
      delete_success: "Media deleted successfully",
      video_not_found: "Video Not Found",
      delete_error: "Unable to delete the clip, Please contact support.",
      from: "From:",
      to: "To:",
      createdOn: "Created On",
      createdBy: "Created By",
      timelapse_inprogress_text: "Time-Lapse request in progress.",
      timelapse_wait_time_text:
        "This may take about 10 minutes. Please check back later.",
      sort_by: {
        first_created: "Last Created",
        last_created: "First Created",
        cameras_desc: "Camera Z-A",
        cameras_asc: "Camera A-Z",
        author_desc: "Creator Z-A",
        author_asc: "Creator A-Z",
        title_asc: "Title A-Z",
        title_desc: "Title Z-A",
        type: "Type",
      },
      media_type: {
        all: "All Media",
        clip: "Cloud Recording Clip",
        local_clip: "Local Recording Clip",
        xray: "XRay",
        compare: "Evercam Compare",
        url: "URL (YouTube, Vimeo...)",
        file: "Upload File",
        edit: "Edit",
        timelapse: "Time-Lapse",
      },
      hints: {
        timelapse: "This opens the Time-Lapse tab",
        compare: "This opens the Compare tab",
        default: "This opens the clip creation wizard",
      },
      no_media: "There's nothing here yet.",
      gallery_info:
        "This area shows all the media (clips, videos, compares etc.) related to your camera.",
      not_found_error: {
        content:
          "You don't have access to the media you are looking for or it's not available. You will be redirected to ",
        non_authorized_content:
          "The Media you are looking for is not Public. You will be redirected to ",
        time_before: "in ",
        time_after: "seconds.",
      },
    },
    weather: {
      title: "Weather",
      no_weather: "Sorry, we have no data available for this date.",
      precipitation: "Precipitation",
      humidity: "Humidity",
      wind: "Wind",
      temperature: "Temperature",
      data_source: "Weather data provided by",
      rain: "Rain",
    },
    units: {
      inch: '"',
      foot: "'",
      metre: "m",
      metric: {
        temperature: "C",
        speed: "km/h",
        speed_ms: "m/s",
        rain: "mm",
      },
      imperial: {
        temperature: "F",
        speed: "mph",
        speed_ms: "mph",
        rain: '"',
      },
    },
    account: {
      delete_failed: "Failed to delete your account",
      update_user_failed: "Failed to update user",
      update_password_failed: "Failed to update password",
      manage_your_avatar: "Manage your avatar with",
      personal_information: "Personal Information",
      update_password: "Update Password",
      theme: "Theme",
      reset_password: "Reset Password",
      reset_password_note:
        "When you click on the button below, a reset password link will be sent to your email",
      reset_password_notification:
        "You will receive a password reset email. Remember to check your spam",
      has_no_password_message:
        "Please, reset your password to get access to Evercam",
      current_password: "Current Password",
      new_password: "New Password",
      confirm_password: "Confirm Password",
      delete_account: "Delete Account",
      delete_account_text:
        "By deleting your account you will lose all your data",
      confirm_delete_account_text:
        "The cameras listed below will be deleted (you can transfer ownership before deleting your account.)",
      transfer_camera: "Transfer Camera",
      type_delete_text:
        "Type your account email address to confirm you would like to delete your account and any cameras you currently own.",
      fetch_access_features_error: "Failed to fetch user features access!",
    },
    sessions: {
      title: [
        "This is a list of devices that have logged into your account.",
        "any sessions that you do not recognize.",
      ],
      revoke: "Revoke",
      revoke_session: "Revoke Session(s)",
      current: "Current",
      headers: {
        id: "Session ID",
        ip_address: "IP Address",
        createdAt: "Created",
        browser: "Browser",
        os: "OS",
        lastUsedAt: "Last used",
        actions: "",
        loginType: "Login type",
      },
      load_sessions_error: "Load active sessions failed, due to error",
      search: "Search",
      content_loading: "Please wait...",
      minimum_selection: "At least select one session",
      revoke_confirm: "Are you sure you want to revoke selected sessions?",
      sessions_deleted: "Session(s) revoked successfully.",
    },
    ptz: {
      zoom_in: "Zoom In",
      zoom_out: "Zoom Out",
      reset_position: "Reset Position",
      move_up: "Move Up",
      move_down: "Move Down",
      move_right: "Move Right",
      move_left: "Move Left",
      presets: "Presets",
      delete_preset: "Are you sure you want to delete the preset:",
      preset_applied_failed: "Preset applied failed.",
      preset_created: "Preset created successfully.",
      preset_updated: "Preset updated successfully.",
      preset_deleted: "Preset deleted successfully.",
      preset_input: "Enter Preset Name",
      location_updated: "Request Sent",
      preset_delete_confirmation:
        "You are about to delete the following preset {presetName}. Are you sure?",
      ptz_box_not_allowed: "You do not have rights to move the camera",
    },
    live_view: {
      web_rtc_load_failed: "Failed to load WebRtc, Switch to JPEG stream",
      inactive_live_view: "Due to inactivity, the live view has been paused.",
      pause_live_view: "Live View Paused",
    },
    minutes: "Minutes",
    hours: "Hours",
    days: "Days",
    model: "Model",
    download_pending:
      "Downloading is in progress, it will take time depending upon the network speed.",
    compare: {
      create_failed: "Failed to create compare",
      before: "Before",
      after: "After",
      compare_saved: "Your compare image has been saved to media hub.",
      overtime_warning:
        "This is taking longer than usual, please visit clip page after some time or contact support for further assistance",
      choose_title:
        "Choose your compare title, then click save, and it will be available in Media Hub.",
    },
    visibility: "Visibility",
    battery_levels: "Battery Levels",
    installed_on: "Installed on",
    shares: {
      fetch_share_request_failed: "Failed to fetch share request by key",
      transfer_camera_failed: "Failed to transfer camera ownership",
      resend_failed: "Failed to resend share request",
      update_rights_failed: "Failed to update share rights",
      create_failed: "Failed to create share",
      switch_visibility_failed: "Failed to switch camera visibility",
      delete_share_failed: "Failed to delete camera share",
      delete_share_success: "Deleted successfully",
      resend_share_request:
        "A notification email has been sent to the specified email address",
      rights_updated: "Rights updated",
      delete_share_request_failed: "Failed to delete camera share request",
      delete_share: "Are you sure you want to delete camera share ?",
      already_shared: "The camera is already shared with user(s)",
      share_success: "Camera successfully shared with user(s)",
      add_user: "Add User",
      visibility_options: {
        this_camera_is: "this camera is",
        public_discoverable: {
          label: "Anyone can discover this camera on the web",
        },
        public_undiscoverable: {
          label: "Anyone with the link can access this camera",
        },
        private: {
          label: "Only people added on the list can see this camera",
        },
      },
      transfer_camera_ownership: {
        description:
          "Transfer ownership to a user who you are already sharing the camera with.",
        note: "Once you transfer, Camera will be shared with you with full rights",
        input: {
          placeholder: "Select a new owner...",
        },
      },
      create_form: {
        toggle_btn: "Share camera",
        title: "Share Camera with",
        rights_labels: {
          read_only: "Read Only",
          read_only_share: "Read + Share",
          full: "Full Rights",
          none: "No Access",
          custom: "Custom",
        },
        message_to: "Message to send in email",
      },
      is_owner: "Is Owner",
      is_restricted: "Restricted (Live view only)",
      headers: {
        shared_with: "Shared With",
        shared_by: "Shared by",
        rights: "Rights",
        last_seen: "Last Seen",
        action: "Action",
      },
    },
    bim: {
      no_bim_found: "BIM images not found for camera",
      transparency: "Transparency",
      no_bim_image: "BIM model not available.",
      export_dialog: {
        bim_in_progress:
          "BIM compare is being exported and will be available in Media Hub shortly (this may take a few minutes).",
        bim_error: "BIM compare export failed",
        instruction_1: "This may take up to 2 minutes.",
        instruction_2:
          "Once completed, links will appear here to view / download.",
        instruction_3: "This will also appear in Media Hub.",
      },
    },
    drone: {
      imagery_hint: "Imagery Plans not available for this project",
      compare_hint:
        "You need at least two flights to enable the compare feature",
      volume_hint: "Volume not available for this flight",
      inspection_hint: "Inspection not available for this flight",
      bim_hint: "A BIM is required to enable this feature",
    },
    _360: {
      compare_hint:
        "You need at least two walkthroughs to enable the compare feature",
      mini_model_hint: "No 3D Mini Model available for this floor",
    },
    site_planner: {
      add_site_plan: "Add Site Plan",
      search_site_plans: "Search a plan by name, id or address",
    },
    connectors: {
      contact_message:
        "Contact us today to connect and improve your workflows. Our team is here to help you get started.",
      contact_us: "Contact us",
      connect: "Connect",
      connect_to_forge_viewer: "Connect to autodesk viewer",
      remove: "Remove",
      upload_to_aconex: "Connect your data to Aconex.",
      upload_to_aconex_failed: "Failed to upload images to Aconex",
      upload_to_procore_failed: "Failed to upload images to Procore",
      upload_to_autodesk_failed: "Failed to upload images to Autodesk",
      connect_to_aconex: "Connect to Aconex",
      aconex_add_success: "Aconex connector added successfully",
      aconex_add_error: "Aconex connector failed, try to connect again",
      aconex_remove_success: "Aconex connector removed successfully",
      aconex_remove_error: "Revoke request failed",
      select_aconex_project: "Select Aconex Project",
      upload_to_procore: "Connect your data to Procore.",
      select_provider: "Select Provider",
      select_procore_project: "Select Procore Project",
      select_procore_album: "Select Project Album",
      no_project_selected: "No project was selected",
      select_autodesk_hub: "Select Autodesk hub",
      select_autodesk_project: "Select Autodesk Project",
      select_autodesk_folder: "Select Autodesk Folder",
      procore_remove_success: "Procore connector removed successfully",
      autodesk_remove_success: "Autodesk connector removed successfully",
      voyage_control_remove_success:
        "VoyageControl connector removed successfully",
      revoke_procore_failed: "Revoke request failed",
      connect_with_procore: "Connect with procore",
      connect_with_aconex: "Connect with aconex",
      connect_with_autodesk: "Connect with autodesk",
      connect_with_aconex_text:
        "Right now you are not connected with Aconex, if you want to upload an image to your Aconex account, you need to connect it first.",
      connect_with_procore_text:
        "Right now you are not connected with Procore, if you want to upload an image to your Procore account, you need to connect it first.",
      connect_with_autodesk_text:
        "Right now you are not connected with Autodesk, if you want to upload an image to your Autodesk account, you need to connect it first.",
      upload_to_autodesk: "Connect your data to Autodesk.",
      revoke_autodesk_failed: "Autodesk revoke request failed",
      revoke_voyage_control_failed: "VoyageControl revoke request failed",
      voyage_control_description: "Connect your data to VoyageControl.",
      connect_to_voyage_control: "Connect to VoyageControl",
      voyage_control_success: "Connected to VoyageControl successfully",
      voyage_control_error: "Failed to connect to VoyageControl",
      reconnect_with_autodesk_viewer_text:
        "If you want to view your model in evercam you need give us access to your viewables.",
      autodesk_model_change_success: "Model updated successfully",
      autodesk_model_change_error: "Failed to update model with autodesk",
      upload_success: "Image uploaded successfully",
      reconnect_autodesk: "Reconnect autodesk",
    },
    user_basic_info_form: {
      missing_information: "Missing account information",
      description:
        "Please take a moment to complete the missing account information",
      checkbox_label: "Don't show this message again",
    },
    pa: {
      title: "Daily, weekly and monthly active users, 2021-2022",
    },
    auth: {
      login_failed: "Failed to login",
      signup_failed: "Failed to signup",
      send_reset_password_failed: "Failed to send reset password",
      reset_password_failed: "Failed to reset password",
    },
    video_wall: {
      camera_selection_title: "Select cameras",
      camera_selection_info:
        "Please select the cameras you would like to display on your videowall grid.",
      grid_configuration_title: "Customize grid configuration",
      grid_configuration_info_1:
        "Grid values are calculated for a 16:9 screen aspect ratio.",
      grid_configuration_info_2:
        "Please resize, Drag and drop to achieve the desired layout.",
      save_configuration_success: "Saved new configuration successfully",
      save_configuration_failed:
        "Failed to save Videowall configuration, please try again",
    },
    notifications: {
      title: "Notifications",
      no_notifications: "No notifications",
      mark_all_as_read: "Mark all as read",
      shared_camera_with_you: "has shared a camera with you",
      camera_went_offline: "Camera <b>{cameraName}</b> has gone offline",
      camera_came_online: "Camera <b>{cameraName}</b> is back online",
      camera_status_change_generic:
        "Camera <b>{cameraName}</b> status changed to <b>{status}</b>",
      timelapsed_created:
        "Time-Lapse for <b>{cameraName}</b> was successfully created.",
      timelapsed_failed:
        "Time-Lapse creation for <b>{cameraName}</b> has failed. Please try again.",
      drone_processing_done:
        "A new flight captured on <b>{droneFlightDate}</b> for <b>{projectName}</b> is now available",
      processing_360_done:
        "A new 360 walkthrough named <b>{floor}</b> captured on <b>{date}</b> for <b>{projectName}</b> is now available",
    },
    ingest: {
      drone: {
        header_text:
          "Drone View offers a detailed representation of the construction site and surrounding area",
        subheader_text1:
          "Locate and access all Evercam features through live camera view easily",
        subheader_text2:
          "Measure distances, heights and areas. Create tags on the model to highlight information.",
        subheader_text3:
          "Track the progress on site and compare different flights in 3D space",
        create: "Upload Your Drone Flight",
      },
      bim: {
        header_text:
          "Explore Building Information Models and Timelines with BIM View by Evercam",
        subheader_text1:
          "Dynamic Visualization: Immerse Yourself in Detailed 3D Models",
        subheader_text2:
          "Measure distances, heights and areas. Create tags on the model to highlight information.",
        subheader_text3:
          "Integrated Project Phases: Seamlessly Navigate Through Timelines",
        create: "Upload Your BIM",
      },
      upload: {
        do_not_close_dashboard:
          "Your files are being uploaded. Do not close the Dashboard.",
        drone_title: "Drone Uploads",
        bim_title: "BIM Uploads",
        files_processing: "Your files are being processed.",
        create_upload_failed: "Failed to create upload !",
      },
    },
    confirmation: "Confirmation",
    comments: {
      comments: "Comments",
      create_failed: "Failed to create new comment",
      create_success: "Comment added successfully",
      delete: "Are you sure you want to delete this comment permanently?",
      archive: "Are you sure you want to archive this comment?",
      delete_failed: "Failed to delete comment",
      delete_success: "Comment deleted successfully",
      archive_failed: "Failed to archive comment",
      archive_success: "Comment archived successfully",
      unarchive_failed: "Failed to unarchive comment",
      unarchive_success: "Comment unarchived successfully",
      no_archived_comments: "No archived comments",
      no_active_comments: "No active comments",
      tabs: {
        active: "Active",
        archived: "Archived",
      },
    },
  },
  settings: {
    select_camera: "Select camera",
    api_key_warning: `API ID & Key are for use by developers.
    Do not share this information, it gives the
    holder access to your Evercam account.`,
    api_documentation: "API Documentation available here.",
  },
  generic_errors: {
    unique: "{target} has already been taken",
    cast: "Invalid field value: {target}",
    required: "{target} is a required field",
    exclusion: "",
    format: "",
    bad_argument: "Validation errors",
    device_error: "Failed to send request to a device",
    unsupported_operation: "Unsupported operation",
    quota_exceeded: "Allowed limit exceeded, retry later.",
    decommissioned_camera: "Invalid operation on decommissioned camera",
    unavailable_location: "Camera has no defined (longitude/latitude)",
    nvr_missing: "Camera is not connected to an NVR",
    nvr_unsupported_operation:
      "The NVR does not support the current operation or not yet implemented",
    nvr_missing_rtsp: "the RTSP port of the NVR is not exposed or reachable",
    password_reset: "You need to reset your password to access Evercam",
    invalid_credentials: "Invalid username or password",
    invalid_token: "Invalid or expired token",
    provider_auth_failure: "Failed to authenticate with provider",
    provider_email_required:
      "Email is missing or not configured in the provider account",
    generic: "500 Internal Server Error",
    project_has_cameras: "Cannot delete a project with associated cameras",
    nvr_offline: "The nvr is offline.",
    unsupported_file_type: "Unsupported file format",
    require_share_request: "Missing share request",
    method_not_allowed: "Camera not support operation.",
    invalid_operation: "Camera not support operation",
    device_unsupported_operation:
      "The device does not support the current operation or not yet implemented",
  },
  copilot: {
    suggestions: {
      site_activity_0: "Were there any safety incidents today?",
      site_activity_1:
        "Give me a report on the construction progress since last week.",
      site_activity_2:
        "Can you provide site activity reports with daily granularity over the last three days?",
      site_activity_3:
        "Are there any high-severity safety reports past few days?",
      site_activity_4: "What happened last week?",
      weather_0:
        "What's the current temperature in the construction site in Fahrenheit?",
      weather_1:
        "What's the current temperature in the construction site in Celsium?",
      weather_2: "Will it rain tomorrow?",
      weather_3: "What's the weather forecast for tomorrow?",
      weather_4: "Give me an hourly weather forecast for today and tomorrow.",
      user_manual_0: "How can I invite a collaborator in Evercam?",
      user_manual_1:
        "How do I integrate Evercam with my existing project management software?",
      user_manual_2: "How do I create Time-Lapse in Evercam?",
      user_manual_3: "How do I create a progress photo in Evercam?",
      user_manual_4: "How can I download a video from Evercam?",
      gate_report_0:
        "Can you provide a detailed report on vehicle detections for the past two days?",
      gate_report_1: "What vehicles were seen today?",
      gate_report_2:
        "How many trucks have entered the construction site this week?",
      gate_report_3:
        "Give me a summary on vehicles at the gate over the weekend?",
      gate_report_4:
        "What plate numbers were detected on site in the last two days?",
      clip: "Create a clip for the last 30 minutes",
    },
    labels: {
      projectExid: "Project",
      cameraExid: "Camera",
      fromDate: "Start Date",
      toDate: "End Date",
    },
  },
}
